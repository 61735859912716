<template>
    <div class="layout-footer">
        <div class="grid">
            <div class="col-12">
                <div class="footer-bottom">
                    <h4>PaymentHub</h4>
                    <h6>Copyright Ⓒ SG Desenvolvimentos</h6>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppFooter",
}
</script>