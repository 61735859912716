<template>
    <div class="menu-wrapper" :class="{ 'layout-sidebar-active': sidebarActive }" @click="onMenuClick"
        @mouseover="onSidebarMouseOver" @mouseleave="onSidebarMouseLeave">
        <div class="sidebar-logo">
            <router-link to="/">
                <img src="layout/images/logo-payment-hub.png">
            </router-link>
            <a class="sidebar-pin" @click="onToggleMenu($event)">
                <span class="pin"></span>
            </a>
        </div>

        <div class="layout-menu-container">
            <AppSubmenu class="layout-menu" :items="menu" :layoutMode="layoutMode" :parentMenuItemActive="true"
                :menuActive="menuActive" :mobileMenuActive="mobileMenuActive" :root="true" @menuitem-click="onMenuItemClick"
                @root-menuitem-click="onRootMenuItemClick" />
        </div>
    </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu"
export default {
    name: "AppMenu",
    emits: ["menu-click", "menuitem-click", "root-menuitem-click", "toggle-menu", "sidebar-mouse-over", "sidebar-mouse-leave"],
    props: {
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean
    },
    data() {
        return {
            menu: [
                { label: "Dashboard", icon: "pi pi-home", to: "/" },
                {
                    label: "Usuários", icon: "pi pi-users", items: [
                        { label: "Criar Usuário", icon: "pi pi-user-plus", to: "/create-users" },
                        { label: "Usuários", icon: "pi pi-users", to: "/users" },
                    ],
                },
                {
                    label: "Fornecedores", icon: "pi pi-dollar", items: [
                        { label: "Cadastrar Fornecedor", icon: "pi pi-plus-circle", to: "/register-vendors" },
                        { label: "Fornecedor", icon: "pi pi-thumbs-up", to: "/vendors" },
                    ],
                },
                {
                    label: "Clientes", icon: "pi pi-server", items: [
                        { label: "Cadastrar Cliente", icon: "pi pi-plus", to: "/register-clients" },
                        { label: "Clientes", icon: "pi pi-database", to: "/clients" },
                    ],
                },
                { label: "Transações", icon: "pi pi-sort-alt", to: "/transactions" },
                { label: "Retirada", icon: "pi pi-arrow-circle-down", to: "/make-removal" },
            ]
        }
    },
    methods: {
        onSidebarMouseOver() {
            if (this.layoutMode === "sidebar" && !this.sidebarStatic) {
                this.$emit("sidebar-mouse-over")
            }
        },
        onSidebarMouseLeave() {
            if (this.layoutMode === "sidebar" && !this.sidebarStatic) {
                setTimeout(() => {
                    this.$emit("sidebar-mouse-leave")
                }, 250)
            }
        },
        onToggleMenu(event) {
            this.$emit("toggle-menu", event)
        },
        onMenuClick(event) {
            this.$emit("menu-click", event)
        },
        onMenuItemClick(event) {
            this.$emit("menuitem-click", event)
        },
        onRootMenuItemClick(event) {
            this.$emit("root-menuitem-click", event)
        }
    },
    components: { AppSubmenu },
}
</script>