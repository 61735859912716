export default (api, resource) => ({
    register: async ({name, type, depositTaxType, depositTaxValue, withdrawTaxType, withdrawTaxValue, active, username, password, merchantId, vendorToken, key, token}) => {
        const response = await api.post(`/${resource}`, {
            name,
            type,
            deposit_tax_type: depositTaxType,
            deposit_tax_value:depositTaxValue,
            withdraw_tax_type: withdrawTaxType,
            withdraw_tax_value: withdrawTaxValue,
            active,
            username,
            password,
            merchantId,
            token: vendorToken,
            key,
        }, {
          headers: {
              Authorization: token
            } 
        })

        return {
          data: response.data
        }
    },

    getAll: async ({ params, token }) => {
        const response = await api.get(`/${resource}?page=${params.page}&perPage=${params.perPage}`, {
          params,  
          headers: {
              Authorization: token
            } 
        })
        
        return {
          data: response.data
        }
    },

    update: async ({modifiedVendor, token}) => {
      
        const response = await api.put(`/${resource}/${modifiedVendor.id}`, {
            ...modifiedVendor,
            merchantId: modifiedVendor.merchant_id,
            urlAuth: modifiedVendor.url_auth,
            urlApi: modifiedVendor.url_api,
            urlWithdraw: modifiedVendor.url_withdraw,
            urlDeposit: modifiedVendor.url_deposit

        }, {
          headers: {
              Authorization: token
            } 
        })

        return {
          data: response.data
        }
    },


    getTypes: async ({ token }) => {
        const response = await api.get(`/${resource}/types`, {  
          headers: {
              Authorization: token
            } 
        })

        return {
          data: response.data
        }
    },

})