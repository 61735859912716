export default (api, resource) => ({

    getAll: async ({ clientId, params, token }) => {
        
        const qsPage = `?page=${params.page}`
        const qsPerPage = `&perPage=${params.perPage}`
        const qsClientId = `&client_id=${clientId}`
        const qsId = `&id=${params.filter.id}`
        const qsCpf = `&cpf=${params.filter.cpf}`
        const qsName = `&name=${params.filter.name}`
        const qsEmail = `&email=${params.filter.email}`
        const qsCode = `&code=${params.filter.code}`
        const qsStatus = `&status=${params.filter.status}`
        
        const response = await api.get(`/${resource}${qsPage}${qsPerPage}${qsClientId}${qsId}${qsCpf}${qsName}${qsEmail}${qsCode}${qsStatus}`, {
          params,  
          headers: {
              Authorization: token
            } 
        })
        
        
        
        return {
          data: response.data
        }
    },

    


})