<template>
    <div class="layout-topbar">
        <div class="layout-topbar-wrapper">
            <div class="layout-topbar-left">
                <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
                    <i class="pi pi-bars"></i>
                </a>
                <router-link id="logo-link" class="layout-topbar-logo" to="/">
                    <img src="layout/images/logo-payment-hub.png">
                </router-link>
            </div>

            <AppMenu :layoutMode="layoutMode" :sidebarActive="sidebarActive" :sidebarStatic="sidebarStatic"
                :menuActive="menuActive" :mobileMenuActive="mobileMenuActive" @sidebar-mouse-leave="onSidebarMouseLeave"
                @sidebar-mouse-over="onSidebarMouseOver" @toggle-menu="onToggleMenu" @menu-click="onMenuClick"
                @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />

            <div class="layout-topbar-right">
                <ul class="layout-topbar-actions">
                    <li ref="profile" class="topbar-item user-profile"
                        :class="{ 'active-topmenuitem fadeInDown': topbarUserMenuActive }">
                        <a @click="onTopbarUserMenuClick">
                            <img src="layout/images/avatar-profilemenu.png" alt="freya-layout" />
                        </a>
                        <ul class="fadeInDown">
                            <li @click="tryTologout()">
                                <span>Sair</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import AppMenu from './AppMenu'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
    name: "AppTopbar",

    emits: ["menu-click", "menuitem-click", "root-menuitem-click", "menu-button-click", "toggle-menu", "right-menubutton-click",
        "sidebar-mouse-over", "sidebar-mouse-leave", "topbar-search-toggle", "topbar-search-click", "topbar-search-hide",
        "topbar-usermenu-click", "update:searchClick"],

    props: {
        searchActive: Boolean,
        searchClick: Boolean,
        topbarItemClick: Boolean,
        topbarUserMenuActive: Boolean,
        topbarUserMenuClick: Boolean,
        activeTopbarItem: String,
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        topbarTheme: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean
    },

    components: {
        AppMenu
    },

    setup(props, context) {

        const store = useStore()
        const router = useRouter()

        function tryTologout() {

            store.dispatch('setLogged', {
                isLogged: false
            })

            router.push({ name: 'Login' })

        }

        function onMenuClick(event) {
            context.emit("menu-click", event)
        }

        function onMenuItemClick(event) {
            context.emit("menuitem-click", event)
        }

        function onRootMenuItemClick(event) {
            context.emit("root-menuitem-click", event)
        }

        function onMenuButtonClick(event) {
            context.emit("menu-button-click", event)
        }

        function onToggleMenu(event) {
            context.emit("toggle-menu", event)
        }

        function onTopbarSearchToggle(event) {
            context.emit('topbar-search-toggle', event)
        }

        function onTopbarSearchClick(event) {
            context.emit("topbar-search-click", event)
        }

        function onInputKeydown(event) {
            const key = event.which

            //escape, tab and enter
            if (key === 27 || key === 9 || key === 13) {
                context.emit("topbar-search-hide", event)
            }
        }

        function onTopbarUserMenuClick(event) {
            context.emit("topbar-usermenu-click", event)
        }

        function onRightMenuButtonClick(event) {
            context.emit("right-menubutton-click", event)
        }

        function onSidebarMouseOver() {
            context.emit("sidebar-mouse-over")
        }

        function onSidebarMouseLeave() {
            context.emit("sidebar-mouse-leave")
        }

        return {
            tryTologout,
            onMenuClick,
            onMenuItemClick,
            onRootMenuItemClick,
            onMenuButtonClick,
            onToggleMenu,
            onTopbarSearchToggle,
            onTopbarSearchClick,
            onInputKeydown,
            onTopbarUserMenuClick,
            onRightMenuButtonClick,
            onSidebarMouseOver,
            onSidebarMouseLeave
        }

    }

}
</script>