import QRCode from 'qrcode'

export default (api, resource) => ({

    getAll: async ({ params, token }) => {

        const playerId = params.player ? params.player.id.toString() : ''
        const clientId = params.filter.client ? params.filter.client.id.toString() : ''
        const status = params.filter.status ? params.filter.status.value : ''
        const type = params.filter.type ? params.filter.type.value : ''

        const qsPage = `?page=${params.page}`
        const qsPerPage = `&perPage=${params.perPage}`
        const qsId = `&id=${params.filter.id}`
        const qsStatus = `&status=${status}`
        const qsType = `&type=${type}`
        const qsClientId = `&clientId=${clientId}`
        const qsVendorName = `&vendorName=${params.filter.vendorName}`
        const qsValue = `&value=${params.filter.value}`
        const qsBegin = `&dateToBegin=${params.filter.date.begin}`
        const qsEnd = `&dateToEnd=${params.filter.date.end}`
        const qsPlayerId = `&playerId=${playerId}`

        const response = await api.get(`/${resource}${qsPage}${qsPerPage}${qsId}${qsStatus}${qsType}${qsClientId}${qsVendorName}${qsValue}${qsBegin}${qsEnd}${qsPlayerId}`, {
          params,  
          headers: {
              Authorization: token
            } 
        })
        
        return {
          data: response.data
        }
    },

    getByIdWithTransactionHistories: async ({ id, token }) => {

        const response = await api.get(`/${resource}/${id}`, 
          {
            id
          }, {
            headers: {
              Authorization: token
            } 
          })

        if(response.data.transaction.type === 'DEPOSIT' && !response.data.transaction.qr_code_image) {
          response.data.transaction.qr_code_image = await QRCode.toDataURL(response.data.transaction.qr_code)
        }

        return {
          data: response.data
        }
    },


    getSummary: async ({ params, token }) => {

        const clientId = params.filter.client ? params.filter.client.id: ''
        const status = params.filter.status ? params.filter.status.value: '' 
  
        const qsClientId = `?clientId=${clientId}`
        const qsBegin = `&initialDate=${params.filter.date.begin}`
        const qsEnd = `&finalDate=${params.filter.date.end}`
        const qsStatus = `&transactionStatus=${status}`
        const qsVendorName = `&vendorName=${params.filter.vendorName}`

        const response = await api.get(`/summary${qsClientId}${qsBegin}${qsEnd}${qsStatus}${qsVendorName}`, {
            headers: {
              Authorization: token
            } 
          })
          
        return {
          data: response.data
        }
        
    },

    createRemoval: async ({vendor, pixKeyType, pixKey, value, player, token}) => {

        const response = await api.post(`/${resource}/removal`, {
            vendorId: vendor.id,
            pixKey,
            pixKeyType: pixKeyType, 
            amount: value,
            player
        }, {
          headers: {
              Authorization: token
            } 
        })

        return {
          data: response.data
        }
    },

})