<template>
    <div v-if="state.expired" class=" fixed w-full">
        <div @click="state.expired = false" class="relative p-3 surface-card w-full inline-flex">
            <div class="flex-1 text-center">Sua sessão expirou, faça login novamente.</div>
            <div class="hover:bg-primary border-round p-1"><i class="pi pi-times"></i></div>
        </div>
    </div>

    <div class="login-body">
        <div class="login-wrapper">
            <form class="login-panel" @submit.prevent="tryToLogIn">
                <a @click="goDashboard" class="logo">
                    <img src="layout/images/logo-payment-hub.png" />
                </a>
                <InputText v-model="state.username" id="username" placeholder="Usuário" />
                <Password v-model="state.password" id="password" placeholder="Senha" :feedback="false" />
                <Button :loading="state.loading" label="Entrar" type="submit"></Button>
            </form>
        </div>
        <div class="login-footer">
            <h4>freya</h4>
            <h6>Copyright Ⓒ PrimeTek Informatics</h6>
        </div>
    </div>

    <NotificationToast />
</template>

<script>
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Password from 'primevue/password'
import services from '../services'
import { reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useNotificationToast from '../composables/useNotificationToast'
import NotificationToast from "@/components/NotificationToast"
export default {
    components: {
        InputText,
        Button,
        Password,
        NotificationToast
    },

    setup() {

        const state = reactive({
            username: '',
            password: '',
            loading: false,
            expired: false,
        })

        const { error } = useNotificationToast()

        const store = useStore()

        const router = useRouter()

        async function tryToLogIn() {
            state.loading = true
            if (!state.username || !state.password) {
                state.loading = false
                return error('Credenciais inválidas')
            }

            await services.auth.login({
                login: state.username,
                password: state.password
            }).then(({ data }) => {

                const { type, token, expiresAt } = data.authorization
                const user = data.user

                store.dispatch('setLogged', {
                    token: `${type} ${token}`,
                    expires_at: expiresAt,
                    isLogged: true
                })

                store.dispatch('setUser', {
                    user: user
                })

                router.push({ name: 'dashboard' })

            }).catch(() => {
                error('Não foi possível carregar os dados do usuário!')
            }).finally(() => {
                state.loading = false
            })
        }

        return {
            state,
            tryToLogIn
        }
    }
}
</script>
