export default (api, resource) => ({
    create: async ({name, login, password, token}) => {

        const response = await api.post(`/${resource}`, {
            name,
            login, 
            password,
        }, {
          headers: {
              Authorization: token
            } 
        })

        return {
          data: response.data
        }
    },
    
    getAll: async ({params, token}) => {
        const response = await api.get(`/${resource}?page=${params.page}&perPage=${params.perPage}`, {
            params,
            headers: {
              Authorization: token
            } 
        })
           
        return {
          data: response.data
        }
    },

    update: async ({modifiedUser, token}) => {
      
        const response = await api.put(`/${resource}/${modifiedUser.id}`, {
            ...modifiedUser,
        }, {
          headers: {
              Authorization: token
            } 
        })

        return {
          data: response.data
        }
    },
})