import {createRouter, createWebHashHistory} from 'vue-router'
import store from '@/store/store'


const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('./pages/Login.vue'),
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/',
        name: 'dashboard',
        exact: true,
        component: () => import('./pages/Dashboard/Dashboard.vue'),
        meta: {
            requiresAuth: true,
            readableName: 'Dashboard',
            icon: 'pi pi-home'
        },
    },
    {
        path: '/create-users',
        name: 'Create Users',
        component: () => import('./pages/Users/CreateUsers.vue'),
        meta: {
            requiresAuth: true,
            readableName: 'Usuários',
            icon: 'pi pi-users'
        },
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import('./pages/Users/Users.vue'),
        meta: {
            requiresAuth: true,
            readableName: 'Usuários',
            icon: 'pi pi-users'
        }
    },
    {
        path: '/register-vendors',
        name: 'Register Vendors',
        component: () => import('./pages/Vendors/RegisterVendors.vue'),
        meta: {
            requiresAuth: true,
            readableName: 'Fornecedores',
            icon: 'pi pi-plus-circle'
        }
    },
    {
        path: '/vendors',
        name: 'Vendors',
        component: () => import('./pages/Vendors/Vendors.vue'),
        meta: {
            requiresAuth: true,     
            readableName: 'Fornecedores',
            icon: 'pi pi-thumbs-up'
        }
    },
    {
        path: '/register-clients',
        name: 'Register Clients',
        component: () => import('./pages/Clients/RegisterClients.vue'),
        meta: {
            requiresAuth: true,
            readableName: 'Clientes',
            icon: 'pi pi-plus-circle'
        }
    },
    {
        path: '/clients',
        
        children: [
            {
                path: '',
                name: 'Clients',
                component: () => import('./pages/Clients/Clients.vue'),
                meta: {
                    requiresAuth: true,     
                    readableName: 'Clientes',
                    icon: 'pi pi-thumbs-up'
                },
            },
            {
                path: ':id/players',
                name: 'players',
                component: () => import('./pages/Clients/Players.vue'),
            },
        ]

    },
    {
        path: '/clients/:id/configurations',
        component: () => import('./pages/Clients/Configurations/Configurations.vue'),
        children: [
            {
                path: '',
                component: () => import('./pages/Clients/Configurations/ConfigurationsVendors.vue'),
                name: 'client-configurations',
                meta: {
                    requiresAuth: true,     
                    readableName: 'Configurações - Fornecedores',
                    icon: 'pi pi-cog',
                    tabMenuIndex: 0,
                },
            },
            {
                path: 'register-configuration',
                name: 'register-configuration',
                component: () => import('./pages/Clients/Configurations/ConfigurationForm.vue'),
                meta: {
                    requiresAuth: true,     
                    readableName: 'Configurações - Registrar Configurações de Fornecedor',
                    icon: 'pi pi-cog',
                    tabMenuIndex: 1,
                },
            },
            {
                path: 'manage-adresses',
                name: 'manage-adresses',
                component: () => import('./pages/Clients/Configurations/ConfigurationsAdresses.vue'),
                meta: {
                    requiresAuth: true,     
                    readableName: 'Configurações - Gerenciar Endereços',
                    icon: 'pi pi-desktop',
                    tabMenuIndex: 2,
                },
            },
        ]
    },
    {
        path: '/transactions',
        name: 'Transactions',
        component: () => import('./pages/Transactions/Transactions.vue'),
        meta: {
            requiresAuth: true,
            readableName: 'Transações',
            icon: 'pi pi-sort-alt'
        }
    },
    {
        path: '/make-removal',
        name: 'Removal',
        component: () => import('./pages/Removal/Removal.vue'),
        meta: {
            requiresAuth: true,
            readableName: 'Retiradas',
            icon: 'pi pi-sort-alt'
        }
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 }
    }
})

router.beforeEach((routeTo, routeFrom, next) => {

    if ((routeTo.name == undefined && routeFrom.name == null) && routeTo.name != "Login") {
        next({ name: 'Login' })
        return
    }

    if (routeTo.meta.requiresAuth){
        if(!store.getters.checkLogged) {
            next({ name: 'Login' })
            return
        }

        if(new Date(store.state.expires_at) <= new Date) {
            next({ name: 'Login', query: {'expired': true } })
            return
        }
    }

    store.commit('updateStoredRoutes',{
        currentRoute: {
            fullPath: routeTo.fullPath,
            meta: routeTo.meta
        },
        previousRoute: {
            fullPath: routeFrom.fullPath,
            meta: routeFrom.meta
        }
    })

    next()
})

export default router