import axios from 'axios'

import AuthService from './auth'
import UsersService from './users'
import VendorsService from './vendors'
import ClientsService from './clients'
import ConfigurationsService from './configurations'
import TransactionsService from './transactions'
import PlayersService from './players'

const ENV = {
  development: process.env.VUE_APP_ROOT_API,
  homologacao: '',
}

const api = axios.create({
  baseURL: ENV[process.env.ENV] || ENV.development
})

export default {
  auth: AuthService(api, 'sessions'),
  users: UsersService(api, 'users'),
  vendors: VendorsService(api, 'vendors'),
  clients: ClientsService(api, 'clients'),
  configurations: ConfigurationsService(api, 'configurations'),
  transactions: TransactionsService(api, 'transactions'),
  players: PlayersService(api, 'players')
}