<template>
    <div class="grid justify-content-between mx-3 mb-3 font-semibold">
        <div class="text-2xl">
            <i class="text-xl" :class="pageHeader?.currentRoute?.meta?.icon || 'pi pi-bookmark'"></i> 
            {{ pageHeader?.currentRoute?.meta?.readableName || 'Relatório' }}
        </div>
        {{ pageHeader?.previousRoute?.fullPath?.meta?.readableName }}
        <div v-if="pageHeader?.previousRoute?.meta?.readableName">
            <router-link :to="pageHeader.previousRoute.fullPath" class="text-lg text-700 hover:text-primary-300">
                <i class="pi pi-arrow-left text-md"></i> 
                {{ pageHeader?.previousRoute.meta.readableName || ' Voltar' }}
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PageHeader',
    computed:{
        pageHeader(){
            return this.$store.state.pageHeader
        }
    }
}
</script>
