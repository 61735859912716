export default (api, resource) => ({
    
    login: async ({ login, password }) => {
        
        const response = await api.post(`/${resource}`, {
            login,
            password
        })
        
        let errors = null

        if (!response.data) {
            errors = {
                status: response.request.status,
                statusText: response.request.statusText
            }
        }

        return {
            data: response.data,
            errors
        }
  },

  logout: async ({token}) => {
        await api.delete(`/${resource}`, {
            headers: {
                    Authorization: token
                }
        })

        return
  },
})