export default (api, resource) => ({
    register: async ({name, callbackUrl , token}) => {
        const response = await api.post(`/${resource}`, {
            name,
            callback_url: callbackUrl,
        }, {
          headers: {
              Authorization: token
            } 
        })
        return {
          data: response.data
        }
    },

    getAll: async ({ params, token }) => {
        const response = await api.get(`/${resource}?page=${params.page}&perPage=${params.perPage}`, {
            params,
            headers: {
              Authorization: token
            } 
        })
        
        return {
          data: response.data
        }
    },

    update: async ({modifiedClient, token}) => {
        
        modifiedClient.fallbackEnabled = modifiedClient.fallbackEnabled == 'Ativado' ? true : false

        const response = await api.put(`/${resource}/${modifiedClient.id}`, {
            ...modifiedClient,
            callback_url: modifiedClient.callbackUrl,
            fallback_enabled : modifiedClient.fallbackEnabled
        }, {
          headers: {
              Authorization: token
            } 
        })

        return {
          data: response.data
        }
    },

    updateToken: async ({clientId, token}) => {

        const response = await api.get(`/${resource}/auth/${clientId}`, {
          headers: {
              Authorization: token
            } 
        })

        return {
          data: response.data
        }
    },


    blockPlayer: async ({ id, cpf, token }) => {

        const response = await api.post(`/${resource}/${id}/cpf/block`, 
          {
            cpf
          }, {
            headers: {
              Authorization: token
            } 
          })

        return {
          data: response.data
        }

    },

    unblockPlayer: async ({ id, cpf, token }) => {
      
        const response = await api.put(`/${resource}/${id}/cpf/unblock`, 
          {
            cpf
          }, {
            headers: {
              Authorization: token
            } 
          })

        return {
          data: response.data
        }

    },


    getAlowedAdresses: async ({ id, token }) => {
        const response = await api.get(`/${resource}/${id}/origin`, 
        {
          headers: {
            Authorization: token
          } 
        })

        return {
          data: response.data
        }

    },

    registerOrigin: async ({id, name, ip,  token}) => {
        const response = await api.post(`/${resource}/${id}/origin/authorize`, {
            name,
            ip,
        }, {
          headers: {
              Authorization: token
            } 
        })
        return {
          data: response.data
        }
    },

    deleteOrigin: async ({id, ip,  token}) => {
        const response = await api.post(`/${resource}/${id}/origin/unauthorize`, {
            ip,
        }, {
          headers: {
              Authorization: token
            } 
        })
        return {
          data: response.data
        }
    },

})
