export default (api, resource) => ({

    getConfigurationsClient: async ({id, token}) => {

        const response = await api.get(`/${resource}/client/${id}`, {
          headers: {
              Authorization: token
            } 
        })
        

        return {
          data: response.data
        }
        
    },

    getAvailableVendors: async ({clientId, token}) => {

        const response = await api.get(`/${resource}/available-vendors/${clientId}`, {
          headers: {
              Authorization: token
            } 
        })

        return {
          data: response.data
        }
        
    },

    create: async ({clientId, vendorsId, configuration, token}) => { 
        
        const response = await api.post(`/${resource}`, {
            client_id: clientId,
            vendors_id: vendorsId,
            ...configuration,
            withdraw_enabled: configuration.withdraw_enabled.value,
            deposit_enabled: configuration.deposit_enabled.value,
            manual_withdraw: configuration.manual_withdraw.value,
        }, {  
            headers: {
                Authorization: token
            } 
        })

        return {
          data: response.data
        }
    },

    update: async ({vendorsId, configuration, token}) => { 

        const response = await api.put(`/${resource}/${configuration.id}`, {
            vendors_id: vendorsId,
            ...configuration,
            withdraw_enabled: configuration.withdraw_enabled.value,
            deposit_enabled: configuration.deposit_enabled.value,
            manual_withdraw: configuration.manual_withdraw.value,
        }, {
            headers: {
                Authorization: token
            } 
        })

        return {
          data: response.data
        }
    },

    delete: async ({configurationId, token}) => { 

        const response = await api.delete(`/${resource}/${configurationId}`, {
            headers: {
                Authorization: token
            } 
        })

        return {
          data: response.data
        }

    },

    prioritize: async ({newPriorityConfigurationsOrder, token}) => { 

        const response = await api.post(`/${resource}/prioritization`, {
            newPriorityConfigurationsOrder: newPriorityConfigurationsOrder
        }, {
            headers: {
                Authorization: token
            } 
        })

        return {
          data: response.data
        }
    }

})